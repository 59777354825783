import React, { useEffect } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, navigate, PageProps } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Layout from 'src/layouts'
import { H1, Headline } from 'src/components/shared/text'
import { PrimaryButton } from 'src/components/shared/buttons'
import { media } from 'src/styles/util'
import { colors } from 'src/styles/variables'

const Section = styled.section`
  position: relative;
  overflow: hidden;

  width: 100%;

  background-color: ${colors.orange};

  ${media.mobile`
    padding: 37px 20px 450px 20px;
    min-height: 100vh;
    margin-top: 123px;
  `}
  ${media.tablet`
    padding: 77px 30px 57px 30px;
    min-height: 60vh;
    margin-top: 123px;
  `}
  ${media.desktop`
    padding: 127px 30px 57px 30px;
    min-height: 100vh;
    margin-top: 123px;
  `}
`

const Content = styled.div`
  display: grid;
  margin: 0 auto;

  ${media.mobile`
    padding: 20px 20px 20px 20px;
  `}
  ${media.tablet`
    height: 100%;
    place-items: center;
    grid-template-columns: 1fr 1fr;
    padding: 0;
  `}
  ${media.desktop`
    grid-template-columns: 1.3fr minmax(auto, 550px);
    max-width: 1200px;
  `}
`

const TextBox = styled.div`
  display: grid;

  ${media.mobile`
    grid-row-gap: 18px;
    text-align: center;
    justify-items: center;

    & button {
      margin-top: 8px;
    }
  `}
  ${media.tablet`
    justify-self: end;
    justify-items: start;
    grid-row-gap: 47px;
    text-align: left;

    & button {
      margin-top: 0;
    }
  `}
`

const DogImageWrapper = styled.div`
  z-index: 0;

  position: absolute;
  bottom: 0;
  left: 0;

  ${media.mobile`
    width: 98vw;
    max-height: 415px;
  `}
  ${media.tablet`
    width: 45vw;
    max-width: 750px;
    max-height: 70vh;
  `}
  ${media.desktop`
    max-width: 1200px;
    max-width: 750px;
    max-height: 80vh;
  `}
`

const query = graphql`
  query {
    dogImg: file(relativePath: { regex: "/404/404-dog.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }
  }
`

const NotFoundPage: React.FC<PageProps> = ({ location }) => {
  const images = useStaticQuery(query)

  useEffect(() => {
    setTimeout(() => {
      navigate('/')
    }, 10000)
  }, [])

  return (
    <Layout location={location}>
      <Section>
        <DogImageWrapper>
          <GatsbyImage
            image={getImage(images.dogImg)}
            alt="Dog in wig"
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </DogImageWrapper>

        <Content>
          <div />
          <TextBox>
            <H1>404</H1>
            <Headline>Sorry! Our dog must have chewed up this page...</Headline>
            <PrimaryButton copy="Go back home" onClick={() => navigate('/')} arrow />
          </TextBox>
        </Content>
      </Section>
    </Layout>
  )
}

export default NotFoundPage
